export const FORM_BOXES = [
  {
    title: "Người gửi",
    icon: "UserIcon",
    items: [
      {
        label: "Tên người gửi",
        field: "senderName",
        type: "text",
        class: "col-span-2",
      },
      {
        label: "Điện thoại người gửi",
        field: "senderPhone",
        type: "text",
        class: "col-span-2",
      },
      {
        label: "Khu vực gửi",
        field: "senderZone",
        type: "senderZone",
        class: "col-span-2",
      },
      {
        label: "Địa chỉ người gửi",
        field: "senderAddress",
        type: "text",
        class: "col-span-2",
      },
    ],
  },
  {
    title: "Người nhận",
    icon: "UserIcon",
    items: [
      {
        label: "Tên người nhận",
        field: "receiverName",
        type: "text",
        class: "col-span-2",
      },
      {
        label: "Điện thoại người nhận",
        field: "receiverPhone",
        type: "text",
        class: "col-span-2",
      },
      {
        label: "Khu vực nhận",
        field: "receiverZone",
        type: "receiverZone",
        class: "col-span-2",
      },
      {
        label: "Địa chỉ người nhận",
        field: "receiverAddress",
        type: "text",
        class: "col-span-2",
      },
    ],
  },
  {
    title: "Hàng hóa",
    icon: "ShoppingBagIcon",
    items: [
      {
        label: "Tên hàng hóa",
        field: "goodsName",
        type: "text",
      },
      {
        label: "Trọng lượng",
        field: "weight",
        type: "text",
      },
      {
        label: "Số kiện",
        field: "quantity",
        type: "text",
      },
      {
        label: "Tiền COD",
        field: "cod",
        type: "text",
      },
      {
        label: "Mã đơn KH",
        field: "customCode",
        type: "text",
      },
      {
        label: "Mã vận đơn",
        field: "billCode",
        type: "text",
      },
    ],
  },
  {
    title: "Vận chuyển",
    icon: "TruckIcon",
    items: [
      {
        label: "PTTT",
        field: "payMethod",
        type: "text",
        readonly: true,
      },
      {
        label: "Trạng thái",
        field: "statusDetail",
        type: "status",
        readonly: true,
      },
      {
        label: "Phí khác",
        field: "otherFees",
        type: "text",
      },
      {
        label: "Vận phí",
        field: "freight",
        type: "text",
        readonly: true,
      },
      {
        label: "Ghi chú",
        field: "remark",
        type: "text",
        class: "col-span-2",
      },
    ],
  },
];


