import { OrderStatusDetailDisplay } from "@/configs/common";

export const initialStatus = [
  {
    display: "Tổng đơn hàng",
    status: undefined,
    count: 0,
  },
  {
    display: "Hủy",
    status: "CANCEL_ORDER",
    count: 0,
  },
  {
    display: "Đợi nhận kiện",
    status: "PENDING",
    count: 0,
  },
  {
    display: "Đã nhận kiện",
    status: "GOT",
    count: 0,
  },
  {
    display: "Đang phát kiện",
    status: "DELIVERING",
    count: 0,
  },
  {
    display: "Ký nhận",
    status: "SIGN",
    count: 0,
  },
  {
    display: "Chuyển hoàn thành công",
    status: "RETURNED_SIGN",
    count: 0,
  },
  {
    display: "Kiện vấn đề",
    status: "ABNORMAL_PARCEL",
    count: 0,
  },
];

export const trackingRenderer = (
  _instance: unknown,
  td: { className: string; innerHTML: string },
  _row: number,
  _col: number,
  _prop: string,
  value: string
): void => {
  td.className = "htMiddle";
  td.innerHTML = `<a href="javascript:void(0);" style="color:blue">Tra hành trình</a>`;
  if (value === "CANCEL_ORDER") {
    td.innerHTML = `<a href="javascript:void(0);" style="color:red">Tạo lại đơn</a>`;
  }
};

export const statusRenderer = (
  _instance: unknown,
  td: { className: string; innerHTML: string },
  _row: number,
  _col: number,
  _prop: string,
  value: string
): void => {
  td.className = "htMiddle";
  if (value === "CANCEL_ORDER") {
    td.innerHTML = `<a href="javascript:void(0);" style="color:red">Hủy đơn đặt</a>`;
  } else {
    td.innerHTML = `<a href="javascript:void(0);" style="color:blue">${OrderStatusDetailDisplay[value]}</a>`;
  }
};

export const addressRenderer = (
  _instance: unknown,
  td: { className: string; innerHTML: string },
  _row: number,
  _col: number,
  _prop: string,
  value: string
): void => {
  td.className = "truncate htMiddle";
  // eslint-disable-next-line no-control-regex
  td.innerHTML = value?.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, ",");
};

export const truncateRenderer = (
  _instance: unknown,
  td: { className: string; innerHTML: string },
  _row: number,
  _col: number,
  _prop: string,
  value: string
): void => {
  td.className = "truncate";
  // eslint-disable-next-line no-control-regex
  td.innerHTML = value;
};

export const booleanRenderer = (
  _instance: unknown,
  td: { className: string; innerHTML: string },
  _row: number,
  _col: number,
  _prop: string,
  value: string
): void => {
  td.className = "htMiddle";
  td.innerHTML = value ? "Có" : "Không";
};

export const columnWidths = [
  30,
  100,
  150,
  120,
  80,
  100,
  120,
  180,
  120,
  100,
  100,
  100,
  180,
  150,
  180,
  150,
  150,
  180,
  250,
  500,
  700,
];

export const signColumnWidths = [
  30,
  100,
  150,
  120,
  50,
  80,
  100,
  120,
  180,
  120,
  100,
  100,
  100,
  180,
  150,
  180,
  150,
  150,
  180,
  250,
  500,
  700,
];

export const columnConfigs = [
  {
    header: "",
    data: "select",
    type: "checkbox",
  },
  {
    header: "Thao tác",
    data: "status",
    readOnly: true,
    renderer: trackingRenderer,
  },
  {
    header: "Trạng thái",
    data: "statusDetail",
    readOnly: true,
    renderer: statusRenderer,
  },
  {
    header: "Mã vận đơn",
    data: "billCode",
    readOnly: true,
  },
  {
    header: "STT Excel",
    readOnly: true,
    data: "excelIndex",
  },
  {
    header: "Số lần in",
    readOnly: true,
    data: "printNumber",
  },
  {
    header: "Mã đơn khách",
    readOnly: true,
    data: "customCode",
    renderer: truncateRenderer,
  },
  {
    header: "Người nhận",
    data: "receiverName",
    readOnly: true,
  },
  {
    header: "Điện thoại",
    data: "receiverPhone",
    readOnly: true,
  },
  {
    header: "Tiền Cod",
    data: "cod",
    type: "numeric",
    readOnly: true,
    numericFormat: {
      pattern: "0,0",
      culture: "en-US", // this is the default culture, set up for USD
    },
  },
  {
    header: "Trọng lượng",
    data: "weight",
    readOnly: true,
  },
  {
    header: "Vận phí",
    data: "freight",
    type: "numeric",
    readOnly: true,
    numericFormat: {
      pattern: "0,0",
      culture: "en-US", // this is the default culture, set up for USD
    },
  },
  {
    header: "Loại kiện vấn đề",
    data: "reason",
    readOnly: true,
  },
  {
    header: "Tên hàng hóa",
    data: "goodsName",
    readOnly: true,
  },
  {
    header: "Ngày tạo đơn",
    data: "createdAt",
    readOnly: true,
  },
  {
    header: "Ngày nhận kiện",
    data: "sendDate",
    readOnly: true,
  },
  {
    header: "Tỉnh/Thành Phố",
    data: "receiverProvince",
    readOnly: true,
  },
  {
    header: "Quận/Huyện",
    data: "receiverDistrict",
    readOnly: true,
  },
  {
    header: "Xã/Phường",
    data: "receiverArea",
    readOnly: true,
  },
  {
    header: "Địa chỉ",
    data: "receiverAddress",
    renderer: addressRenderer,
    readOnly: true,
  },
  {
    header: "Ghi Chú",
    data: "remark",
    readOnly: true,
  },
];

export const signColumnConfigs = [
  {
    header: "",
    data: "select",
    type: "checkbox",
  },
  {
    header: "Thao tác",
    data: "status",
    readOnly: true,
    renderer: trackingRenderer,
  },
  {
    header: "Trạng thái",
    data: "statusDetail",
    readOnly: true,
    renderer: statusRenderer,
  },
  {
    header: "Mã vận đơn",
    data: "billCode",
    readOnly: true,
  },
  {
    header: "GH1P",
    data: "isSignPart",
    readOnly: true,
    renderer: booleanRenderer,
  },
  {
    header: "STT Excel",
    readOnly: true,
    data: "excelIndex",
  },
  {
    header: "Số lần in",
    readOnly: true,
    data: "printNumber",
  },
  {
    header: "Mã đơn khách",
    readOnly: true,
    data: "customCode",
    renderer: truncateRenderer,
  },
  {
    header: "Người nhận",
    data: "receiverName",
    readOnly: true,
  },
  {
    header: "Điện thoại",
    data: "receiverPhone",
    readOnly: true,
  },
  {
    header: "Tiền Cod",
    data: "cod",
    type: "numeric",
    readOnly: true,
    numericFormat: {
      pattern: "0,0",
      culture: "en-US", // this is the default culture, set up for USD
    },
  },
  {
    header: "Trọng lượng",
    data: "weight",
    readOnly: true,
  },
  {
    header: "Vận phí",
    data: "freight",
    type: "numeric",
    readOnly: true,
    numericFormat: {
      pattern: "0,0",
      culture: "en-US", // this is the default culture, set up for USD
    },
  },
  {
    header: "Loại kiện vấn đề",
    data: "reason",
    readOnly: true,
  },
  {
    header: "Tên hàng hóa",
    data: "goodsName",
    readOnly: true,
  },
  {
    header: "Ngày tạo đơn",
    data: "createdAt",
    readOnly: true,
  },
  {
    header: "Ngày nhận kiện",
    data: "sendDate",
    readOnly: true,
  },
  {
    header: "Tỉnh/Thành Phố",
    data: "receiverProvince",
    readOnly: true,
  },
  {
    header: "Quận/Huyện",
    data: "receiverDistrict",
    readOnly: true,
  },
  {
    header: "Xã/Phường",
    data: "receiverArea",
    readOnly: true,
  },
  {
    header: "Địa chỉ",
    data: "receiverAddress",
    renderer: addressRenderer,
    readOnly: true,
  },
  {
    header: "Ghi Chú",
    data: "remark",
    readOnly: true,
  },
];
