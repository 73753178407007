
import { defineComponent, PropType, ref } from "vue";
import { Order } from "@/configs/orders";
import OrderEditForm from "@/components/order-edit-form/Main.vue";

export default defineComponent({
  name: "OrderModal",
  components: {
    OrderEditForm,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      default: {} as any,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const formRef = ref(null as any);

    return {
      formRef,
    };
  },
});
