import { computed, onBeforeMount, ref } from "vue";

import { useStore } from "@/store";
import { CountryAddress } from "@/configs/jtTypes";
import { helper } from "@/utils/helper";

export const usePicker: any = (props: any, { emit }: any) => {
  const store = useStore();
  const countryAddresses = computed(() => store.state.main.countryAddresses);
  const selectedProvince = ref<CountryAddress>({} as any);
  const selectedDistrict = ref<CountryAddress>({} as any);
  const selectedArea = ref<CountryAddress>({} as any);
  const provinceSearch = ref<string>("");
  const districtSearch = ref<string>("");
  const areaSearch = ref<string>("");
  const state = ref<string>("province");

  const provinceOptions = computed(() => {
    return countryAddresses.value.filter((item) => {
      return helper
        .removeVietnameseTones(item.nameBig)
        .includes(
          helper.removeVietnameseTones(provinceSearch.value.toUpperCase())
        );
    });
  });

  const districtOptions = computed(() => {
    return selectedProvince.value.childNodes?.filter((item) => {
      return helper
        .removeVietnameseTones(item.nameBig)
        .includes(
          helper.removeVietnameseTones(districtSearch.value.toUpperCase())
        );
    });
  });

  const areaOptions = computed(() => {
    return selectedDistrict.value.childNodes?.filter((item) => {
      return helper
        .removeVietnameseTones(item.nameBig)
        .includes(helper.removeVietnameseTones(areaSearch.value.toUpperCase()));
    });
  });

  const setState = (selectedState: string) => {
    state.value = selectedState;
  };

  const setProvince = (province: CountryAddress) => {
    selectedProvince.value = province;
    emit("province-selected", province.shorthandName);
    state.value = "district";
  };

  const setDistrict = (district: CountryAddress) => {
    selectedDistrict.value = district;
    emit("district-selected", district.shorthandName);
    state.value = "area";
  };

  const setArea = (area: CountryAddress) => {
    emit("area-selected", area.name);
    selectedArea.value = area;
    cash(`#${props.id}`).addClass("hidden");
  };

  onBeforeMount(() => {
    store.dispatch("main/SET_COUNTRY_ADDRESS");
  });

  return {
    state,
    setState,
    provinceSearch,
    setProvince,
    districtSearch,
    setDistrict,
    provinceOptions,
    districtOptions,
    areaOptions,
    selectedProvince,
    selectedDistrict,
    selectedArea,
    areaSearch,
    setArea,
  };
};
