<template>
  <div id="order-edit-form" class="modal">
    <div class="modal__content modal__content--xxl p-10 text-center">
      <div class="mb-5">
        <OrderEditForm ref="formRef" :order="order" :readonly="readonly" />
      </div>
      <a
        v-if="!readonly"
        href="javascript:;"
        class="button w-24 mr-4 inline-block bg-theme-1 text-white"
      >
        Xác nhận
      </a>
      <a
        href="javascript:;"
        class="button w-24 mr-4 inline-block bg-theme-2 text-gray-500"
        data-dismiss="modal"
      >
        Huỷ
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { Order } from "@/configs/orders";
import OrderEditForm from "@/components/order-edit-form/Main.vue";

export default defineComponent({
  name: "OrderModal",
  components: {
    OrderEditForm,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      default: {} as any,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const formRef = ref(null as any);

    return {
      formRef,
    };
  },
});
</script>
