<template>
  <div class="flex-col justify-between mt-2">
    <!-- BEGIN: Filter -->
    <div class="intro-y box px-5 pt-2">
      <div
        class="grid grid-cols-12 border-b border-gray-200 dark:border-dark-5 pb-2 -mx-5 gap-2 pl-2"
      >
        <div class="col-span-5">
          <input
            v-model="formData.query"
            type="text"
            class="input w-full border"
            placeholder="Nhập số theo dõi, số điện thoại hoặc mã khách hàng"
          />
        </div>
        <div class="col-span-2">
          <select class="input border w-full" v-model="formData.dateGroupBy">
            <option value="send_date">Ngày nhận kiện</option>
            <option value="sign_at">Ngày ký nhận</option>
          </select>
        </div>
        <div class="col-span-2">
          <Litepicker
            v-model="formData.dateRange"
            :options="{
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="input w-full border block mx-auto"
          />
        </div>
        <div class="col-span-2">
          <button
            type="button"
            class="button bg-theme-1 flex justify-center text-white"
            @click="search"
          >
            <LoadingIcon color="white" class="w-4 h-4 mr-1" v-if="loading" />
            <SearchIcon class="w-4 h-4 mr-1" v-else /> Tìm kiếm
          </button>
        </div>
      </div>
      <div
        class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start"
      >
        <a
          v-for="status in orderStatus"
          :key="status.status"
          data-toggle="tab"
          href="javascript:;"
          class="py-4 sm:mr-8"
          style="font-size: 12px"
          :class="`${status.status === undefined ? 'active' : ''}`"
          @click="changeStatus(status.status)"
        >
          {{ `${status.display}(${status.count})` }}
        </a>
      </div>
    </div>
    <!-- END: Filter -->
    <!-- BEGIN: Function Button -->
    <div class="intro-y flex flex-col-reverse sm:flex-row items-center mt-4">
      <div class="w-full sm:w-auto flex">
        <button
          class="button button--sm text-white bg-theme-4 items-center justify-center flex shadow-md mr-2"
          @click="showExportModal"
        >
          <LoadingIcon color="white" class="w-4 h-4 mr-1" v-if="loading" />
          <FileTextIcon class="w-4 h-4 mr-1" v-else /> Truy xuất
        </button>
      </div>
    </div>
    <div class="box mt-4 handson2 z-0">
      <div
        id="handson"
        ref="tableRef"
        class="hot handsontable htRowHeaders htColumnHeaders"
        style="height: 100%; overflow: hidden; width: 100%"
        data-originalstyle="height: 100%; overflow: hidden; width: 100%;"
      ></div>
    </div>
    <!-- End: Handson Table -->
    <div>
      <Pagination
        :pagination="pagination"
        @handlePaginationChange="handlePaginationChange"
      />
    </div>

    <div class="flex box justify-between p-5 mt-5">
      <span class="mr-5 2xl:font-bold text-base">
        <span>Tổng vận đơn: </span>
        <span class="text-theme-1">{{ $h.formatCurrency(summary.orders ?? 0) }}</span>
      </span>
      <span class="mr-5 2xl:font-bold text-base">
        <span>Tổng trọng lượng: </span>
        <span class="text-theme-1">{{ $h.formatCurrency(summary.weight ?? 0) }}</span>
      </span>
      <span class="mr-5 2xl:font-bold text-base">
        <span>Vận phí: </span>
        <span class="text-theme-1">{{ $h.formatCurrency(summary.freight ?? 0) }}</span>
      </span>
      <span class="mr-5 2xl:font-bold text-base">
        <span>Tổng Cod: </span>
        <span class="text-theme-1">{{ $h.formatCurrency(summary.cod ?? 0) }}</span>
      </span>
    </div>
  </div>
  <ExportModal @export-all="exportAll" @export-selected="exportSelected" />
  <OrderEditModal :order="selectedOrder" :readonly="true" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useOrder } from "./useOrder";
import Pagination from "@/components/pagination/Main.vue";
import ExportModal from "@/components/export-modal/Main.vue";
import OrderEditModal from "@/components/order-edit-modal/Main.vue";
import "handsontable/dist/handsontable.full.css";

export default defineComponent({
  components: {
    Pagination,
    ExportModal,
    OrderEditModal,
  },
  setup() {
    const {
      tableRef,
      orderStatus,
      formData,
      orders,
      handlePaginationChange,
      search,
      pagination,
      changeStatus,
      columns,
      loading,
      exportSelected,
      exportAll,
      summary,
      selectedOrder,
    } = useOrder();
    return {
      tableRef,
      orderStatus,
      formData,
      handlePaginationChange,
      columns,
      pagination,
      orders,
      changeStatus,
      loading,
      summary,
      search,
      exportSelected,
      exportAll,
      selectedOrder,
    };
  },
  methods: {
    showExportModal() {
      cash("#export-modal").modal("show");
    },
  },
});
</script>
