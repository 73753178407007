
import { defineComponent } from "vue";
import { usePicker } from "./usePicker";

export default defineComponent({
  name: "AddressPicker",
  props: ["id"],
  setup(props, { emit }) {
    const {
      state,
      setState,
      provinceSearch,
      selectedProvince,
      setProvince,
      selectedDistrict,
      districtOptions,
      areaOptions,
      setDistrict,
      provinceOptions,
      selectedArea,
      districtSearch,
      areaSearch,
      setArea,
    } = usePicker(props, { emit });

    return {
      state,
      setState,
      selectedProvince,
      setProvince,
      districtOptions,
      areaOptions,
      selectedDistrict,
      setDistrict,
      provinceSearch,
      selectedArea,
      provinceOptions,
      districtSearch,
      areaSearch,
      setArea,
    };
  },
});
