
import { defineComponent, PropType } from "vue";
import { Order } from "@/configs/orders";
import { useForm } from "./useForm";
import { FORM_BOXES } from "./configs";
import vClickOutside from "@/libs/click-outside";
import AddressPicker from "@/components/address-picker/Main.vue";
import { OrderStatusDetailDisplay } from "@/configs/common";

export default defineComponent({
  name: "OrderForm",
  directives: {
    clickOutside: vClickOutside,
  },
  components: {
    AddressPicker,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
    },
    small: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FORM_BOXES,
      OrderStatusDetailDisplay,
    };
  },
  setup(props) {
    const { formData, validate, validateForm, updateFormData, senderZone, receiverZone } = useForm(props);

    return {
      formData,
      validate,
      validateForm,
      updateFormData,
      senderZone,
      receiverZone,
    };
  },
  methods: {
    showSenderAddressPicker() {
      // if (cash("#sender-address-picker").hasClass("hidden")) {
      //   cash("#sender-address-picker").removeClass("hidden");
      // } else {
      //   cash("#sender-address-picker").addClass("hidden");
      // }
    },
    hideSenderAddressPicker() {
      cash("#sender-address-picker").addClass("hidden");
    },
    showReceiverAddressPicker() {
      // if (cash("#receiver-address-picker").hasClass("hidden")) {
      //   cash("#receiver-address-picker").removeClass("hidden");
      // } else {
      //   cash("#receiver-address-picker").addClass("hidden");
      // }
    },
    hideReceiverAddressPicker() {
      cash("#receiver-address-picker").addClass("hidden");
    },
  },
});
