
import { defineComponent } from "vue";
import { useOrder } from "./useOrder";
import Pagination from "@/components/pagination/Main.vue";
import ExportModal from "@/components/export-modal/Main.vue";
import OrderEditModal from "@/components/order-edit-modal/Main.vue";
import "handsontable/dist/handsontable.full.css";

export default defineComponent({
  components: {
    Pagination,
    ExportModal,
    OrderEditModal,
  },
  setup() {
    const {
      tableRef,
      orderStatus,
      formData,
      orders,
      handlePaginationChange,
      search,
      pagination,
      changeStatus,
      columns,
      loading,
      exportSelected,
      exportAll,
      summary,
      selectedOrder,
    } = useOrder();
    return {
      tableRef,
      orderStatus,
      formData,
      handlePaginationChange,
      columns,
      pagination,
      orders,
      changeStatus,
      loading,
      summary,
      search,
      exportSelected,
      exportAll,
      selectedOrder,
    };
  },
  methods: {
    showExportModal() {
      cash("#export-modal").modal("show");
    },
  },
});
