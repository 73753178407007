import { ExportField } from "@/configs/jtTypes";

const left = [
  {
    fieldName: "status",
    header: "Trạng thái",
  },
  {
    fieldName: "billCode",
    header: "Mã vận đơn",
  },
  {
    fieldName: "goodsName",
    header: "Tên hàng hóa",
  },
  {
    fieldName: "freight",
    header: "Vận phí",
  },
  {
    fieldName: "payMethod",
    header: "Loại thanh toán",
  },
  {
    fieldName: "remark",
    header: "Ghi chú",
  },
];
const middle = [
  {
    fieldName: "customCode",
    header: "Mã đơn KH",
  },
  {
    fieldName: "senderName",
    header: "Người gửi",
  },
  {
    fieldName: "senderPhone",
    header: "Điện thoại Người gửi",
  },
  {
    fieldName: "receiverName",
    header: "Người nhận",
  },
  // {
  //   fieldName: "signatureType",
  //   header: "Phương thức ký nhận",
  // },
  {
    fieldName: "cod",
    header: "Tiền COD",
  },
  {
    fieldName: "receiverAddress",
    header: "Địa chỉ",
  },
  // {
  //   fieldName: "expressType",
  //   header: "Dịch Vụ",
  // },
  {
    fieldName: "receiverProvince",
    header: "Tỉnh",
  },
  {
    fieldName: "receiverDistrict",
    header: "Thành phố",
  },
];
const right = [
  {
    fieldName: "id",
    header: "Mã đơn đặt",
  },
  {
    fieldName: "receiverPhone",
    header: "Điện thoại Người nhận",
  },
  {
    fieldName: "weight",
    header: "Trọng lượng",
  },
  {
    fieldName: "insuranceFee",
    header: "Phí khác",
  },
  {
    fieldName: "sendDate",
    header: "Thời gian",
  },
  {
    fieldName: "reason",
    header: "Loại kiện vấn đề",
  },
  // {
  //   fieldName: "categroy",
  //   header: "Loại hàng",
  // },
  {
    fieldName: "receiverArea",
    header: "Khu vực",
  },
];

export const defaultExportColumns: ExportField[][] = [left, middle, right];

export const allExportColumns: ExportField[] = [...left, ...middle, ...right];

export const defaultColumns = [
  "status",
  "billCode",
  "goodsName",
  "freight",
  "payMethod",
  "remark",
  "customCode",
  "receiverName",
  "cod",
  "receiverAddress",
  "id",
  "receiverPhone",
  "weight",
  "reason",
  "insuranceFee",
  "sendDate",
];
