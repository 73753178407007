<template>
  <div
    class="absolute chat top-10 left-0 z-50 w-full flex flex-col box h-80 hidden"
    :id="id"
  >
    <div class="mt-2 w-full">
      <div class="chat__tabs nav-tabs justify-center flex">
        <a
          data-toggle="tab"
          data-target="#sender-province"
          href="javascript:;"
          @click="setState('province')"
          class="flex-1 py-1 h-8 w-25 rounded-md text-center"
          :class="{ active: state === 'province' }"
        >
          Tỉnh
        </a>
        <a
          data-toggle="tab"
          data-target="#sender-district"
          href="javascript:;"
          @click="setState('district')"
          class="flex-1 py-1 h-8 rounded-md text-center w-60"
          :class="{ active: state === 'district' }"
        >
          Quận/Huyện</a
        >
        <a
          data-toggle="tab"
          data-target="#sender-area"
          href="javascript:;"
          @click="setState('area')"
          class="flex-1 py-1 h-8 rounded-md text-center w-60"
          :class="{ active: state === 'area' }"
        >
          Phường/Xã
        </a>
      </div>
    </div>
    <div class="tab-content pos p-3 w-full">
      <div
        id="sender-province"
        class="tab-content__pane"
        :class="{ active: state === 'province' }"
      >
        <input
          class="w-full h-7 border"
          placeholder="Tìm kiếm Tỉnh/Thành phố"
          v-model="provinceSearch"
        />
        <div class="pos__ticket mt-2 h-52 overflow-auto" v-if="provinceOptions">
          <a
            v-for="province in provinceOptions"
            :key="province?.id"
            href="javascript:;"
            @click="setProvince(province)"
            class="flex items-center p-1 cursor-pointer transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-1 rounded-md"
            :class="{
              'bg-theme-1 text-white':
                province.shorthandName === selectedProvince.shorthandName,
            }"
          >
            {{ province?.shorthandName }}
          </a>
        </div>
      </div>
      <div
        id="sender-district"
        class="tab-content__pane"
        :class="{ active: state === 'district' }"
      >
        <input
          class="w-full h-7 border"
          placeholder="Tìm kiếm Quận/Huyện"
          v-model="districtSearch"
        />
        <div class="pos__ticket mt-2 h-52 overflow-auto" v-if="districtOptions">
          <a
            v-for="district in districtOptions"
            :key="district.id"
            href="javascript:;"
            @click="setDistrict(district)"
            class="flex items-center p-1 cursor-pointer transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-1 rounded-md"
            :class="{
              'bg-theme-1 text-white':
                district.shorthandName === selectedDistrict.shorthandName,
            }"
          >
            {{ district.shorthandName }}
          </a>
        </div>
      </div>
      <div
        id="sender-area"
        class="tab-content__pane"
        :class="{ active: state === 'area' }"
      >
        <input
          class="w-full h-7 border"
          placeholder="Tìm kiếm Phường/Xã"
          v-model="areaSearch"
        />
        <div class="pos__ticket mt-2 h-52 overflow-auto" v-if="areaOptions">
          <a
            v-for="area in areaOptions"
            :key="area.id"
            href="javascript:;"
            @click="setArea(area)"
            class="flex items-center p-1 cursor-pointer transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-1 rounded-md"
            :class="{
              'bg-theme-1 text-white':
                area.shorthandName === selectedArea.shorthandName,
            }"
          >
            {{ area.shorthandName }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { usePicker } from "./usePicker";

export default defineComponent({
  name: "AddressPicker",
  props: ["id"],
  setup(props, { emit }) {
    const {
      state,
      setState,
      provinceSearch,
      selectedProvince,
      setProvince,
      selectedDistrict,
      districtOptions,
      areaOptions,
      setDistrict,
      provinceOptions,
      selectedArea,
      districtSearch,
      areaSearch,
      setArea,
    } = usePicker(props, { emit });

    return {
      state,
      setState,
      selectedProvince,
      setProvince,
      districtOptions,
      areaOptions,
      selectedDistrict,
      setDistrict,
      provinceSearch,
      selectedArea,
      provinceOptions,
      districtSearch,
      areaSearch,
      setArea,
    };
  },
});
</script>
